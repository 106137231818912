import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

class Newsletters extends React.Component {
  render() {
    const { edges: posts } = this.props;

    return (
      <div
        className="columns is-multiline"
        style={{ position: "relative", zIndex: "15" }}
      >
        {posts &&
          posts.map(({ node: post }) => (
            <div className="column is-6" key={post.id}>
              {post.frontmatter.image ? (
                <GatsbyImage
                  image={post.frontmatter.image.childImageSharp.gatsbyImageData}
                  style={{ width: "100%" }}
                  alt={`featured image thumbnail for post ${post.frontmatter.title}`}
                />
              ) : null}
            </div>
          ))}
      </div>
    );
  }
}

Newsletters.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default Newsletters;
